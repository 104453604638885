import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Buffer } from 'buffer';

import App from './App';
import './i18n';
import 'assets/css/index.css';
import 'assets/css/commonModal.scss';
import 'react-tooltip/dist/react-tooltip.css';

// eslint-disable-next-line no-undef
globalThis.Buffer = Buffer;
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
if (isIOS) {
  document.body.classList.add('ios-device');
}
const isAndroid = /Android/.test(navigator.userAgent);
if (isAndroid) {
  document.body.classList.add('android-device');
}
const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
