import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const Loading = () => (
  <div className="absolute h-full left-0 top-0 w-full z-[100]">
    <FaSpinner size="30" className="animate-spin left-1/2 absolute top-1/2" />
  </div>
);

export default Loading;
