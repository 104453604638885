import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { TooltipProvider } from 'react-tooltip';
import { PrimeReactProvider } from 'primereact/api';
import { Toaster } from 'react-hot-toast';

import pages from 'pages';

import 'typeface-montserrat';
import 'typeface-source-serif-pro';
import 'moment/dist/locale/fr';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5000,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <TooltipProvider>
        <PrimeReactProvider>
          {pages}
          <Toaster />
          <ReactQueryDevtools initialIsOpen={false} />
        </PrimeReactProvider>
      </TooltipProvider>
    </BrowserRouter>
  </QueryClientProvider>
);

export default App;
